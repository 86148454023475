import { debug } from "@/utils/debugHelper";
const ctxLog = (...args) => {
    debug("BugTracker", ...args);
};

class BugTrackerService {
    limit = 0;
    counter = 0;
    sentErrors: any[] = [];
    enabled = false;

    constructor({ limit }: { limit: number }) {
        this.limit = limit;
        this.counter = 0;
        this.sentErrors = [];
    }

    send(e) {
        if (!this.enabled) return;

        ctxLog("env:", process.env.ENV);

        if (process.env.ENV == "localhost") return;

        this.counter++;
        this.sentErrors.push(e);

        const data = {
            message: e.message,
            timestamp: new Date().toISOString(),
            environment: process.env.ENV,
            request: {
                url: window?.location?.href,
                headers: {
                    Referer: document?.referrer,
                    "User-Agent": navigator?.userAgent,
                },
            },
            level: "error",
            platform: "javascript",
        };

        try {
            const xhr = new XMLHttpRequest();
            xhr.open(
                "POST",
                "https://sentry.io/api/4506478719991808/store/",
                true
            );
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.setRequestHeader(
                "X-Sentry-Auth",
                "Sentry sentry_version=7, sentry_key=, sentry_client=raven-bash/0.1"
            );

            xhr.send(JSON.stringify(data));
        } catch (ex) {
            ctxLog("Failed to send:", ex);
        }
    }
}

export { BugTrackerService };
