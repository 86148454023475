import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { IBreadcrumb } from "@/types";
import { cacheKeys, getItem, setItem } from "@/utils/cache";

export const useUIStore = defineStore("ui", () => {
    const isAppLoaded = ref(false);
    const sidebarEnabled = ref(true);
    const sidebarCollapsed = ref(
        (getItem(cacheKeys.sidebarCollapsed) as boolean) || false
    );
    const toolbarEnabled = ref(true);
    const headerHidden = ref(false);
    const title = ref("");
    const description = ref("");
    const breadcrumbs = ref<Array<IBreadcrumb>>([]);

    function setAppLoaded() {
        isAppLoaded.value = true;
    }

    function addBodyClassName(className: string) {
        document.body.classList.add(className);
    }

    function removeBodyClassName(className: string) {
        document.body.classList.remove(className);
    }

    function addBodyAttribute(payload) {
        const { qulifiedName, value } = payload;
        document.body.setAttribute(qulifiedName, value);
    }

    function removeBodyAttribute(payload) {
        const { qulifiedName } = payload;
        document.body.removeAttribute(qulifiedName);
    }

    function enableSidebar() {
        addBodyClassName("aside-enabled");
        sidebarEnabled.value = true;
    }

    function disableSidebar() {
        removeBodyClassName("aside-enabled");
        sidebarEnabled.value = false;
    }

    function hideSidebar() {
        addBodyAttribute({
            qulifiedName: "data-aside-minimize",
            value: "on",
        });
        setItem(cacheKeys.sidebarCollapsed, true);
        sidebarCollapsed.value = true;
    }

    function showSidebar() {
        removeBodyAttribute({
            qulifiedName: "data-aside-minimize",
        });
        setItem(cacheKeys.sidebarCollapsed, false);
        sidebarCollapsed.value = false;
    }

    function enableToolbar() {
        addBodyClassName("toolbar-enabled");
        addBodyClassName("toolbar-fixed");
        addBodyClassName("toolbar-tablet-and-mobile-fixed");
        toolbarEnabled.value = true;
    }

    function disableToolbar() {
        removeBodyClassName("toolbar-enabled");
        removeBodyClassName("toolbar-fixed");
        removeBodyClassName("toolbar-tablet-and-mobile-fixed");
        toolbarEnabled.value = false;
    }

    function showHeader() {
        headerHidden.value = false;
    }

    function hideHeader() {
        headerHidden.value = true;
    }

    function setTitle(
        pageTitle: string,
        pageDescription: string,
        pageBreadcrumbs: IBreadcrumb[]
    ) {
        title.value = pageTitle;
        description.value = pageDescription;
        breadcrumbs.value = pageBreadcrumbs;
        headerHidden.value = false;
    }

    return {
        isAppLoaded,
        sidebarEnabled,
        sidebarCollapsed,
        toolbarEnabled,
        headerHidden,
        title,
        description,
        breadcrumbs,
        setAppLoaded,
        enableSidebar,
        disableSidebar,
        hideSidebar,
        showSidebar,
        enableToolbar,
        disableToolbar,
        showHeader,
        hideHeader,
        addBodyClassName,
        removeBodyClassName,
        addBodyAttribute,
        removeBodyAttribute,
        setTitle,
    };
});
