import { orderBy } from "lodash";
import { defineStore } from "pinia";
import { computed, reactive, ref } from "vue";
import { INotice } from "@/types";

export const useNoticeStore = defineStore("notice", () => {
    const notices = ref<INotice[]>([]);

    function addNotice(notice: INotice) {
        notices.value.push(notice);
    }

    function removeNotice(notice: INotice) {
        notices.value = notices.value.filter((n) => n.id != notice.id);
    }

    return {
        notices,
        addNotice,
        removeNotice,
    };
});
