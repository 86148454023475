import { objectHasProp } from "@/types";

export function isDOMNode(o: any) {
    if (!o) return false;
    return typeof Node === "object"
        ? o instanceof Node
        : true &&
              typeof o === "object" &&
              typeof o.nodeType === "number" &&
              typeof o.nodeName === "string";
}

export function isDOMElement(o: any) {
    return isDOMNode(o) && typeof HTMLElement === "object"
        ? o instanceof HTMLElement // DOM2
        : true &&
              typeof o === "object" &&
              o !== null &&
              o.nodeType === 1 &&
              typeof o.nodeName === "string";
}

export function getPropertyValueByKey(o: any, key: string): any | undefined {
    const map = new Map(Object.entries(o));

    // eslint-disable-next-line no-prototype-builtins
    if (objectHasProp(o, key) && map) {
        return map.get(key);
    }
}

export function deepFreeze<T>(object: T) {
    // Retrieve the property names defined on object
    const propNames = Object.getOwnPropertyNames(object);
    // Freeze properties before freezing self
    for (const name of propNames) {
        const value = object[name];

        object[name] =
            value && typeof value === "object" ? deepFreeze(value) : value;
    }

    return Object.freeze(object);
}

/**
 * Encodes given object into url-friendly format
 *
 * @param object An object that contains serializable values
 * @returns string Encoded
 */
function urlEncode(object) {
    return Object.keys(object)
        .map(
            (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`
        )
        .join("&");
}
