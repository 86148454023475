// eslint-disable-next-line @typescript-eslint/unbound-method
export const objectToString = Object.prototype.toString;

/**
 * Checks whether given value is an instance of the given built-in class.
 *
 * @param wat The value to be checked
 * @param className
 * @returns A boolean representing the result.
 */
export function isBuiltin(wat, className) {
    return objectToString.call(wat) === `[object ${className}]`;
}

/**
 * Checks whether given value's type is an instance of provided constructor.
 * {@link isInstanceOf}.
 *
 * @param wat A value to be checked.
 * @param base A constructor to be used in a check.
 * @returns A boolean representing the result.
 */
export function isInstanceOf(wat, base) {
    try {
        return wat instanceof base;
    } catch (_e) {
        return false;
    }
}

/**
 * Checks whether given value's type is a Vue ViewModel.
 *
 * @param wat A value to be checked.
 * @returns A boolean representing the result.
 */
export function isVueViewModel(wat) {
    // Not using Object.prototype.toString because in Vue 3 it would read the instance's Symbol(Symbol.toStringTag) property.
    return !!(
        typeof wat === "object" &&
        wat !== null &&
        (wat.__isVue || wat._isVue)
    );
}

export * from "./boolean";
export * from "./error";
export * from "./function";
export * from "./null";
export * from "./number";
export * from "./empty";
export * from "./object";
export * from "./string";
export * from "./regexp";
export * from "./event";
export * from "./element";
export * from "./primitive";
