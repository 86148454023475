import { defineStore } from "pinia";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { AxiosResponse } from "axios";
import {
    ILoginParams,
    IForgotPasswordParams,
    IResetPasswordParams,
    IAuthUser,
    IUser,
    IUserPreferences,
    IApiUserPreferencesResponse,
} from "@/types";
import { UserPreferenceKey } from "@/models/user";
import ApiService from "@/services/ApiService";
import JwtService from "@/services/JwtService";
import { handleError } from "@/utils/error-handling";

export const useAuthStore = defineStore("auth", () => {
    const authUser = ref(JwtService.getAuthUser());
    const isUserLoggedIn = ref(!!JwtService.getAuthUser());
    const preferences = ref<IUserPreferences | null>(null);

    const router = useRouter();
    const route = useRoute();

    function login(params: ILoginParams) {
        return new Promise((resolve, reject) => {
            ApiService.post("/api/v1/login", params)
                .then((response) => {
                    const { data: user }: { data: IAuthUser } = response.data;

                    authUser.value = user;
                    isUserLoggedIn.value = true;

                    JwtService.saveAuthUser(authUser.value);

                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    function logout() {
        return new Promise((resolve, reject) => {
            ApiService.delete("/api/v1/logout")
                .then((response) => {
                    authUser.value = null;
                    isUserLoggedIn.value = false;

                    JwtService.destroyAuthUser();

                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    function forgotPassword(params: IForgotPasswordParams) {
        return new Promise((resolve, reject) => {
            ApiService.post("/api/v1/forgot-password", params)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    function resetPassword(params: IResetPasswordParams) {
        return new Promise((resolve, reject) => {
            return ApiService.post("/api/v1/reset-password", params)
                .then((response) => {
                    const { data } = response;
                    const { data: user }: { data: IUser } = data;

                    authUser.value = user;
                    isUserLoggedIn.value = true;

                    JwtService.saveAuthUser(authUser.value);

                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    function authenticate() {
        return new Promise((resolve, reject) => {
            ApiService.get("/api/v1/me")
                .then((response) => {
                    const { data: user }: { data: IUser } = response.data;

                    authUser.value = user;
                    isUserLoggedIn.value = true;

                    JwtService.saveAuthUser(authUser.value);

                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    function destroySession() {
        return new Promise((resolve, reject) => {
            try {
                authUser.value = null;
                isUserLoggedIn.value = false;

                JwtService.destroyAuthUser();

                resolve(authUser);
            } catch (err) {
                reject(err);
            }
        });
    }

    function getPreferences() {
        return new Promise((resolve, reject) => {
            ApiService.get("/api/v1/me/preferences")
                .then(
                    (response: AxiosResponse<IApiUserPreferencesResponse>) => {
                        const { data } = response.data;

                        preferences.value = data;

                        resolve(response);
                    }
                )
                .catch((err) => {
                    reject(err);
                });
        });
    }

    function storePreferences(data) {
        return new Promise((resolve, reject) => {
            ApiService.post("/api/v1/me/preferences", data)
                .then(
                    (response: AxiosResponse<IApiUserPreferencesResponse>) => {
                        const { data: savedPreferences } = response.data;

                        preferences.value = savedPreferences;

                        resolve(response);
                    }
                )
                .catch((err) => {
                    reject(err);
                });
        });
    }

    function deletePreferences(preferenceKey: UserPreferenceKey | null) {
        return new Promise((resolve, reject) => {
            let resource = "/api/v1/me/preferences";

            if (preferenceKey) {
                resource += `?key=${preferenceKey}`;
            }

            ApiService.delete(resource)
                .then((response) => {
                    const keyIndex = preferenceKey?.split(".")[1];
                    const newPreferences = { ...preferences.value };

                    if (keyIndex && newPreferences.reports) {
                        delete newPreferences.reports[keyIndex];
                    }

                    console.log(newPreferences, "oldPreferences");

                    preferences.value = newPreferences;

                    console.log(preferences.value, "preferences.value");

                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    return {
        authUser,
        isUserLoggedIn,
        preferences,
        login,
        logout,
        forgotPassword,
        resetPassword,
        authenticate,
        destroySession,
        getPreferences,
        storePreferences,
        deletePreferences,
    };
});
