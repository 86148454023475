import DataHelper from "@/utils/DataHelper";
import ElementAnimateHelper from "@/utils/ElementAnimateHelper";
import ElementStyleHelper from "@/utils/ElementStyleHelper";
import EventHandlerHelper from "@/utils/EventHandlerHelper";
import {
    getElementOffset,
    getScrollTop,
    getAttributeValueByBreakpoint,
    getCSS,
    domId,
} from "@/utils/dom";
import { getPropertyValueByKey } from "@/utils/objectHelper";
import { snakeToCamel } from "@/utils/stringHelper";

export interface StickyOptions {
    offset: number;
    reverse: boolean;
    animation: boolean;
    animationSpeed: string;
    animationClass: string;
}

const defaultStickyOptions: StickyOptions = {
    offset: 200,
    reverse: false,
    animation: true,
    animationSpeed: "0.3s",
    animationClass: "animation-slide-in-down",
};

class Sticky {
    element: HTMLElement;
    options: StickyOptions;
    instanceUid: string;
    instanceName: string | null = "";
    attributeName: string;
    eventTriggerState: boolean;
    lastScrollTop: number;

    constructor(_element: HTMLElement, options: StickyOptions) {
        this.element = _element;
        this.options = Object.assign(defaultStickyOptions, options);
        this.instanceUid = domId("sticky");
        this.instanceName = this.element.getAttribute("data-sticky-name");
        this.attributeName = `data-sticky-${this.instanceName}`;
        this.eventTriggerState = true;
        this.lastScrollTop = 0;

        // Event Handlers
        window.addEventListener("scroll", this.scroll);

        // Initial Launch
        this.scroll();

        DataHelper.set(this.element, "sticky", this);
    }

    private scroll = () => {
        const offset = this.getOption("offset");
        const reverse = this.getOption("reverse");

        // Exit if false
        if (offset === false) {
            return;
        }

        let offsetNum = 0;
        if (typeof offset === "string") {
            offsetNum = parseInt(offset);
        }

        const st = getScrollTop();

        // Reverse scroll mode
        if (reverse === true) {
            // Release on reverse scroll mode
            if (st > offsetNum && this.lastScrollTop < st) {
                if (document.body.hasAttribute(this.attributeName) === false) {
                    this.enable();
                    document.body.setAttribute(this.attributeName, "on");
                }

                if (this.eventTriggerState) {
                    EventHandlerHelper.trigger(this.element, "kt.sticky.on");
                    EventHandlerHelper.trigger(
                        this.element,
                        "kt.sticky.change"
                    );

                    this.eventTriggerState = false;
                }
            } else {
                // Back scroll mode
                if (document.body.hasAttribute(this.attributeName)) {
                    this.disable();
                    document.body.removeAttribute(this.attributeName);
                }

                if (!this.eventTriggerState) {
                    EventHandlerHelper.trigger(this.element, "kt.sticky.off");
                    EventHandlerHelper.trigger(
                        this.element,
                        "kt.sticky.change"
                    );

                    this.eventTriggerState = true;
                }
            }

            this.lastScrollTop = st;
            return;
        }

        // Classic scroll mode
        if (st > offsetNum) {
            if (!document.body.hasAttribute(this.attributeName)) {
                this.enable();
                document.body.setAttribute(this.attributeName, "on");
            }

            if (this.eventTriggerState) {
                EventHandlerHelper.trigger(this.element, "kt.sticky.on");
                EventHandlerHelper.trigger(this.element, "kt.sticky.change");
                this.eventTriggerState = false;
            }
        } else {
            // back scroll mode
            if (document.body.hasAttribute(this.attributeName)) {
                this.disable();
                document.body.removeAttribute(this.attributeName);
            }

            if (!this.eventTriggerState) {
                EventHandlerHelper.trigger(this.element, "kt.sticky.off");
                EventHandlerHelper.trigger(this.element, "kt.sticky.change");
                this.eventTriggerState = true;
            }
        }
    };

    private getOption = (name: string) => {
        const dataStickyAttr = `data-sticky-${name}`;
        if (this.element.hasAttribute(dataStickyAttr)) {
            const attrValueInStr = this.element.getAttribute(dataStickyAttr);
            const attrValue = getAttributeValueByBreakpoint(
                attrValueInStr || ""
            );
            if (attrValue !== null && String(attrValue) === "true") {
                return true;
            } else if (attrValue !== null && String(attrValue) === "false") {
                return false;
            }

            return attrValue;
        } else {
            const optionName = snakeToCamel(name);
            const option = getPropertyValueByKey(
                this.options,
                optionName
            );
            if (option) {
                return getAttributeValueByBreakpoint(option);
            }
        }
    };

    private disable = () => {
        ElementStyleHelper.remove(this.element, "top");
        ElementStyleHelper.remove(this.element, "width");
        ElementStyleHelper.remove(this.element, "left");
        ElementStyleHelper.remove(this.element, "right");
        ElementStyleHelper.remove(this.element, "z-index");
        ElementStyleHelper.remove(this.element, "position");
    };

    private enable = (update = false) => {
        const top = this.getOption("top");
        const left = this.getOption("left");
        // const right = this.getOption("right");
        let width = this.getOption("width");
        const zindex = this.getOption("zindex");

        if (update !== true && this.getOption("animation") === true) {
            ElementStyleHelper.set(
                this.element,
                "animationDuration",
                this.getOption("animationSpeed")
            );
            ElementAnimateHelper.animateClass(
                this.element,
                `animation ${this.getOption("animationClass")}`
            );
        }

        if (zindex !== null) {
            ElementStyleHelper.set(this.element, "z-index", zindex);
            ElementStyleHelper.set(this.element, "position", "fixed");
        }

        if (top !== null) {
            ElementStyleHelper.set(this.element, "top", top);
        }

        if (width !== null && width !== undefined) {
            const widthTarget = getPropertyValueByKey(width, "target");
            if (widthTarget) {
                const targetElement = document.querySelector(widthTarget);
                if (targetElement) {
                    width = getCSS(targetElement, "width");
                }
            }
            ElementStyleHelper.set(this.element, "width", width);
        }

        if (left !== null) {
            if (String(left).toLowerCase() === "auto") {
                const offsetLeft = getElementOffset(this.element).left;

                if (offsetLeft > 0) {
                    ElementStyleHelper.set(
                        this.element,
                        "left",
                        `${String(offsetLeft)}px`
                    );
                }
            }
        }
    };

    public update = () => {
        if (document.body.hasAttribute(this.attributeName)) {
            this.disable();
            document.body.removeAttribute(this.attributeName);
            this.enable(true);
            document.body.setAttribute(this.attributeName, "on");
        }
    };

    // Event API
    // eslint-disable-next-line @typescript-eslint/ban-types
    public on = (name: string, callBack: Function) => {
        return EventHandlerHelper.on(this.element, name, callBack);
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    public one = (name: string, callback: Function) => {
        return EventHandlerHelper.one(this.element, name, callback);
    };

    public off = (name: string) => {
        return EventHandlerHelper.off(this.element, name);
    };

    public trigger = (name: string) => {
        return EventHandlerHelper.trigger(this.element, name);
    };

    // Static methods
    public static hasInstace(element: HTMLElement) {
        return DataHelper.has(element, "sticky");
    }

    public static getInstance(element: HTMLElement) {
        if (element !== null && Sticky.hasInstace(element)) {
            return DataHelper.get(element, "sticky");
        }
    }

    // Create Instances
    public static createInstances(selector: string) {
        const elements = document.body.querySelectorAll(selector);
        elements.forEach((element) => {
            const item = element as HTMLElement;
            let sticky = Sticky.getInstance(item);
            if (!sticky) {
                sticky = new Sticky(item, defaultStickyOptions);
            }
        });
    }

    public static createInsance = (
        selector: string,
        options: StickyOptions = defaultStickyOptions
    ): Sticky | undefined => {
        const element = document.body.querySelector(selector);
        if (!element) {
            return;
        }
        const item = element as HTMLElement;
        let sticky = Sticky.getInstance(item);
        if (!sticky) {
            sticky = new Sticky(item, options);
        }
        return sticky;
    };

    public static bootstrap(attr = '[data-sticky="true"]') {
        Sticky.createInstances(attr);
    }

    public static reInitialization(attr = '[data-sticky="true"]') {
        Sticky.createInstances(attr);
    }
}

export { Sticky, defaultStickyOptions };
