import { keys, map } from "lodash";
import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/services/JwtService";
import type { AxiosResponse } from "axios";

interface IApiError {
    errorBag: string;
    errorMessage: string;
}

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
    /**
     * @description property to share vue instance
     */
    public static vueInstance: App;

    /**
     * @description initialize vue axios
     */
    public static init(app: App<Element>) {
        ApiService.vueInstance = app;
        ApiService.vueInstance.use(VueAxios, axios);
        ApiService.vueInstance.axios.interceptors.request.use((config) => {
            // this.setProgressBar()

            /*if (JwtService.hasToken()) {
                return {
                    ...config,
                    headers: {
                        ...config.headers,
                        Authorization: `Bearer ${JwtService.getToken()}`,
                    },
                };
            }*/

            if (JwtService.hasAuthUser()) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                config.headers.Authorization = `Bearer ${
                    JwtService.getAuthUser()?.api_token
                }`;
            }

            return config;
        });
    }

    /**
     * @description set the default HTTP request headers
     */
    // public static setHeader() {
    //     ApiService.vueInstance.axios.defaults.headers.common[
    //         "Authorization"
    //     ] = `Token ${JwtService.getToken()}`;
    //     ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
    //         "application/json";
    // }

    // public static query(url: string, params): Promise<AxiosResponse> {
    //     return ApiService.vueInstance.axios.get(resource, params);
    // }

    public static get(url: string, params = {}): Promise<AxiosResponse> {
        let query = "";

        if (keys(params).length > 0) {
            query =
                "?" +
                map(params, (value, key) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (value.constructor === Array) {
                        return map(value, (v) => `${key}=${v}`).join("&");
                    } else {
                        return `${key}=${value}`;
                    }
                }).join("&");
        }

        url += query;

        return new Promise((resolve, reject) => {
            ApiService.vueInstance.axios
                .get(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    const normalizedErr = ApiService.normalizeErrorBag(err);
                    reject(normalizedErr);
                });
        });
    }

    public static post(url: string, data): Promise<AxiosResponse> {
        return new Promise((resolve, reject) => {
            ApiService.vueInstance.axios
                .post(url, data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    const normalizedErr = ApiService.normalizeErrorBag(err);
                    reject(normalizedErr);
                });
        });
    }

    public static update(resource: string, data): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.put(resource, data);
    }

    public static put(resource: string, data): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.put(resource, data);
    }

    public static delete(resource: string): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.delete(resource);
    }

    public static normalizeErrorBag(err) {
        const errorBag: unknown[] = [];

        if (err.response.data.errors) {
            // const responseErrors =
            //     JSON.parse(JSON.stringify(err.response.data.errors)) || {};

            errorBag.push(Object.values(err.response.data.errors).flat() as []);

            // for (const i in errors) {
            //     errorBag.push(errors[i][0]);
            // }
        } else if (err.response.data.error) {
            errorBag.push(err.response.data.error);
        } else {
            errorBag.push(err.response.data.message);
        }

        err.response.data.errors = errorBag;

        return err;
    }
}

export default ApiService;
