import DOMEventHelper from "@/utils/DOMEventHelper";
import EventHandlerHelper from "@/utils/EventHandlerHelper";
import ElementStyleHelper from "@/utils/ElementStyleHelper";
import { getAttributeValueByBreakpoint, getCSS, throttle, domId } from "@/utils/dom";
import { getPropertyValueByKey } from "@/utils/objectHelper";
import { snakeToCamel } from "@/utils/stringHelper";

export class DrawerStore {
    static store: Map<string, Drawer> = new Map();

    public static set(instanceId: string, drawerComponentObj: Drawer): void {
        if (DrawerStore.has(instanceId)) {
            return;
        }

        DrawerStore.store.set(instanceId, drawerComponentObj);
    }

    public static get(instanceId: string): Drawer | undefined {
        if (!DrawerStore.has(instanceId)) {
            return;
        }
        return DrawerStore.store.get(instanceId);
    }

    public static remove(instanceId: string): void {
        if (!DrawerStore.has(instanceId)) {
            return;
        }

        DrawerStore.store.delete(instanceId);
    }

    public static has(instanceId: string): boolean {
        return DrawerStore.store.has(instanceId);
    }

    public static getAllInstances() {
        return DrawerStore.store;
    }
}

export interface DrawerOptions {
    overlay: boolean;
    baseClass: string;
    overlayClass: string;
    direction: string;
}

const defaultDrawerOptions: DrawerOptions = {
    overlay: true,
    baseClass: "drawer",
    overlayClass: "drawer-overlay",
    direction: "end",
};

class Drawer {
    element: HTMLElement;
    overlayElement: HTMLElement | null = null;
    toggleElement: HTMLElement | null = null;
    options: DrawerOptions;
    instanceUid: string;
    name = "";
    shown = false;
    lastWidth = 0;
    closeElement: HTMLElement | null = null;

    constructor(_element: HTMLElement, options: DrawerOptions) {
        this.element = _element;
        this.options = Object.assign(defaultDrawerOptions, options);
        this.instanceUid = domId("drawer");
        this.overlayElement = null;
        this.name = this.element.getAttribute("data-drawer-name") || "";
        this.shown = false;
        this.toggleElement = null;
        // Event Handlers
        this._handlers();
        // Update Instance
        this._update();
        // Bind Instance
        DrawerStore.set(this.element.id, this);
    }

    private _handlers = () => {
        const togglers = this._getOption("toggle") as string;
        const closers = this._getOption("close") as string;

        if (togglers !== null && togglers.length > 0) {
            DOMEventHelper.on(document.body, togglers, "click", (e: Event) => {
                e.preventDefault();

                this.toggleElement = document.getElementById(
                    togglers.replace("#", "")
                );
                this._toggle();
            });
        }

        if (closers !== null && closers.length > 0) {
            DOMEventHelper.on(document.body, closers, "click", (e: Event) => {
                e.preventDefault();

                this.closeElement = document.getElementById(
                    closers.replace("#", "")
                );
                this._hide();
            });
        }
    };

    private _update = () => {
        const width = String(this._getOption("width"));
        const direction = String(this._getOption("direction"));

        // Reset state
        const hasBaseClass = this.element.classList.contains(
            `${this.options.baseClass}-on`
        );
        const bodyCanvasAttr = String(
            document.body.getAttribute(`data-drawer-${this.name}-`)
        );

        this.shown = hasBaseClass && bodyCanvasAttr === "on";

        // Activate/deactivate
        if (this._getOption("activate") === true) {
            this.element.classList.add(this.options.baseClass);
            this.element.classList.add(
                `${this.options.baseClass}-${direction}`
            );
            ElementStyleHelper.set(this.element, "width", width, true);

            this.lastWidth = parseInt(width);
        } else {
            ElementStyleHelper.set(this.element, "width", "");
            this.element.classList.remove(this.options.baseClass);
            this.element.classList.remove(
                `${this.options.baseClass}-${direction}`
            );
            this._hide();
        }
    };

    private _getOption = (name: string) => {
        const attr = this.element.getAttribute(`data-drawer-${name}`);
        if (attr) {
            const value = getAttributeValueByBreakpoint(attr);
            if (value !== null && String(value) === "true") {
                return true;
            } else {
                if (value !== null && String(value) === "false") {
                    return false;
                }
            }

            return value;
        } else {
            const optionName = snakeToCamel(name);
            const option = getPropertyValueByKey(this.options, optionName);
            if (option) {
                return getAttributeValueByBreakpoint(option);
            } else {
                return null;
            }
        }
    };

    private _toggle = () => {
        if (
            EventHandlerHelper.trigger(this.element, "kt.drawer.toggle") ===
            false
        ) {
            return;
        }

        if (this.shown) {
            this._hide();
        } else {
            this._show();
        }

        EventHandlerHelper.trigger(this.element, "kt.drawer.toggled");
    };

    private _hide = () => {
        if (
            EventHandlerHelper.trigger(this.element, "kt.drawer.hide") === false
        ) {
            return;
        }

        this.shown = false;
        this._deleteOverlay();
        document.body.removeAttribute(`data-drawer-${this.name}`);
        document.body.removeAttribute(`data-drawer`);
        this.element.classList.remove(`${this.options.baseClass}-on`);

        this.toggleElement?.setAttribute("aria-expanded", "false");
        this.toggleElement?.classList.remove("active");

        EventHandlerHelper.trigger(this.element, "kt.drawer.after.hidden");
    };

    private _show = () => {
        if (
            EventHandlerHelper.trigger(this.element, "kt.drawer.show") === false
        ) {
            return;
        }

        this.shown = true;
        this._createOverlay();
        document.body.setAttribute(`data-drawer-${this.name}`, "on");
        document.body.setAttribute("data-drawer", "on");
        this.element.classList.add(`${this.options.baseClass}-on`);

        this.toggleElement?.setAttribute("aria-expanded", "true");
        this.toggleElement?.classList.add("active");

        EventHandlerHelper.trigger(this.element, "kt.drawer.shown");
    };

    private _createOverlay = () => {
        if (this._getOption("overlay") === true) {
            this.overlayElement = document.createElement("DIV");
            const elementZIndex = getCSS(this.element, "z-index");
            if (elementZIndex) {
                const overlayZindex = parseInt(elementZIndex) - 1;
                ElementStyleHelper.set(
                    this.overlayElement,
                    "z-index",
                    overlayZindex
                ); // update
            }
            document.body.append(this.overlayElement);
            const overlayClassOption = this._getOption("overlay-class");
            if (overlayClassOption) {
                this.overlayElement.classList.add(
                    overlayClassOption.toString()
                );
            }
            this.overlayElement.addEventListener("click", (e) => {
                e.preventDefault();
                this._hide();
            });
        }
    };

    private _deleteOverlay = () => {
        if (this.overlayElement !== null && this.overlayElement.parentNode) {
            this.overlayElement.parentNode.removeChild(this.overlayElement);
        }
    };

    private _getDirection = () => {
        return String(this._getOption("direction")) === "left"
            ? "left"
            : "right";
    };

    private _getWidth = () => {
        let width = this._getOption("width");
        if (width && width === "auto") {
            width = getCSS(this.element, "width");
        }

        return width;
    };

    ///////////////////////
    // ** Public API  ** //
    ///////////////////////
    public toggle = () => {
        this._toggle();
    };

    public show = () => {
        this._show();
    };

    public hide = () => {
        this._hide();
    };

    public isShown = () => {
        return this.shown;
    };

    public update = () => {
        this._update();
    };

    public goElement = () => {
        return this.element;
    };

    // Event API
    public on = (name: string, handler: Function) => {
        return EventHandlerHelper.on(this.element, name, handler);
    };

    public one = (name: string, handler: Function) => {
        return EventHandlerHelper.one(this.element, name, handler);
    };

    public off = (name: string) => {
        return EventHandlerHelper.off(this.element, name);
    };

    public trigger = (name: string, event: Event) => {
        return EventHandlerHelper.trigger(this.element, name, event);
    };

    // Static methods
    public static hasInstace = (elementId: string): boolean => {
        return DrawerStore.has(elementId);
    };

    public static getInstance = (elementId: string) => {
        return DrawerStore.get(elementId);
    };

    public static hideAll = () => {
        const oldInstances = DrawerStore.getAllInstances();
        oldInstances.forEach((dr) => {
            dr.hide();
        });
    };

    public static updateAll = () => {
        const oldInstances = DrawerStore.getAllInstances();
        oldInstances.forEach((dr) => {
            dr.update();
        });
    };

    // Create Instances
    public static createInstances(selector: string): void {
        const elements = document.body.querySelectorAll(selector);
        elements.forEach((element) => {
            const item = element as HTMLElement;
            let drawer = Drawer.getInstance(item.id);
            if (!drawer) {
                drawer = new Drawer(item, defaultDrawerOptions);
            }
            drawer.element = item;
            drawer.hide();
        });
    }

    // Dismiss instances
    public static handleDismiss = () => {
        // External drawer toggle handler
        DOMEventHelper.on(
            document.body,
            '[data-drawer-dismiss="true"]',
            "click",
            () => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                /* @ts-ignore */
                const element = this.closest('[data-drawer="true"]');
                if (element) {
                    const drawer = Drawer.getInstance(element);
                    if (drawer && drawer.isShown()) {
                        drawer.hide();
                    }
                }
            }
        );
    };

    // Global Initialization
    public static initGlobalHandlers(): void {
        // Window Resize Handling
        window.addEventListener("resize", () => {
            let timer: number | undefined;
            throttle(
                timer,
                () => {
                    // Locate and update Drawer instances on window resize
                    const elements = document.body.querySelectorAll(
                        '[data-drawer="true"]'
                    );
                    elements.forEach((el) => {
                        const item = el as HTMLElement;
                        const instance = Drawer.getInstance(item.id);
                        if (instance) {
                            instance.element = item;
                            instance.update();
                        }
                    });
                },
                200
            );
        });
    }

    public static bootstrap = () => {
        Drawer.createInstances('[data-drawer="true"]');
        Drawer.initGlobalHandlers();
        Drawer.handleDismiss();
    };

    public static reinitialization = () => {
        Drawer.createInstances('[data-drawer="true"]');
        Drawer.hideAll();
        Drawer.updateAll();
        Drawer.handleDismiss();
    };
}

export { Drawer, defaultDrawerOptions };
