export enum UserType {
    EMPLOYEE = "employee",
    CONTACT = "contact",
}

export enum UserRole {
    ADMIN = "admin",
    ACCOUNT_MANAGER = "account-manager",
    PROJECT_MANAGER = "project-manager",
    ADMIN_ASSISTANT = "admin-assistant",
    MARKETING_SPECIALIST = "marketing-specialist",
    GRAPHIC_DESIGNER = "graphic-designer",
    COPYWRITER = "copywriter",
    DEVELOPER = "developer",
    CONTACT = "contact",
    OTHER = "other",
}

export enum UserPreferenceKey {
    REPORTS_DISPLAYED_COLUMNS = "reports.displayed_columns",
}
