import { storeToRefs } from "pinia";
import type { RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import { UserType } from "@/models/user";
import { useAuthStore } from "@/stores/AuthStore";

const AuthenticatedRoutes: Array<RouteRecordRaw> = [
    {
        path: "/",
        // redirect: "/dashboard",
        component: () =>
            import(/* webpackChunkName: "app" */ "@/views/app/Index.vue"),
        meta: { requiresAuth: true },
        children: [
            {
                path: "",
                name: "dashboard",
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/Index.vue"
                    ),
            },
            {
                path: "/clients",
                name: "clients",
                component: () =>
                    import(
                        /* webpackChunkName: "clients" */ "@/views/clients/Index.vue"
                    ),
            },
            {
                path: "/clients/:client_id",
                name: "client",
                component: () =>
                    import(
                        /* webpackChunkName: "client-layout" */ "@/views/clients/ClientLayout.vue"
                    ),
                children: [
                    {
                        path: "",
                        name: "client.view",
                        component: () =>
                            import(
                                /* webpackChunkName: "client-view" */ "@/views/clients/View.vue"
                            ),
                    },
                    {
                        path: "employees",
                        name: "client.employees",
                        component: () =>
                            import(
                                /* webpackChunkName: "client-employees" */ "@/views/clients/employees/Index.vue"
                            ),
                    },
                    /*{
                        path: "companies",
                        name: "client.companies",
                        component: () =>
                            import(
                                /!* webpackChunkName: "companies" *!/ "@/views/companies/Index.vue"
                            ),
                    },*/
                    /*{
                        path: "companies/:company_id",
                        name: "client.company",
                        component: () =>
                            import(
                                /!* webpackChunkName: "company-layout" *!/ "@/views/companies/CompanyLayout.vue"
                            ),
                        children: [
                            {
                                path: "",
                                name: "client.company.view",
                                component: () =>
                                    import(
                                        /!* webpackChunkName: "company-view" *!/ "@/views/companies/View.vue"
                                    ),
                            },
                        ],
                    },*/
                    {
                        path: "contacts",
                        name: "client.contacts",
                        component: () =>
                            import(
                                /* webpackChunkName: "client-contacts" */ "@/views/clients/contacts/Index.vue"
                            ),
                    },
                    {
                        path: "reports",
                        name: "client.reports",
                        component: () =>
                            import(
                                /* webpackChunkName: "client-reports" */ "@/views/clients/reports/Index.vue"
                            ),
                    },
                    {
                        path: "reports/:report_id",
                        name: "client.report",
                        component: () =>
                            import(
                                /* webpackChunkName: "client-report-layout" */ "@/views/clients/reports/ReportLayout.vue"
                            ),
                        children: [
                            {
                                path: "",
                                name: "client.report.view",
                                component: () =>
                                    import(
                                        /* webpackChunkName: "client-report-view" */ "@/views/clients/reports/View.vue"
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                path: "/employees",
                name: "employees",
                component: () =>
                    import(
                        /* webpackChunkName: "employees" */ "@/views/employees/Index.vue"
                    ),
                /*meta: {
                    permissions: ["admin", "editor"], // you can set roles in root nav
                },*/
            },
            {
                path: "/employees/:employee_id",
                name: "employee",
                component: () =>
                    import(
                        /* webpackChunkName: "employee-layout" */ "@/views/employees/EmployeeLayout.vue"
                    ),
                children: [
                    {
                        path: "",
                        name: "employee.view",
                        component: () =>
                            import(
                                /* webpackChunkName: "employee-view" */ "@/views/employees/View.vue"
                            ),
                    },
                ],
            },
            {
                path: "/services",
                name: "services",
                component: () =>
                    import(
                        /* webpackChunkName: "services" */ "@/views/services/Index.vue"
                    ),
            },
            {
                path: "/forms",
                name: "forms",
                component: () =>
                    import(
                        /* webpackChunkName: "forms" */ "@/views/services/Index.vue"
                    ),
            },
            {
                path: "/onboarding",
                component: () =>
                    import(
                        /* webpackChunkName: "onboarding" */ "@/views/onboarding/Onboarding.vue"
                    ),
                children: [
                    /*{
                        path: "",
                        name: "onboarding.platform",
                        component: () =>
                            import(
                                /!* webpackChunkName: "onboarding-platform" *!/ "@/views/onboarding/NewPlatform.vue"
                            ),
                    },*/
                    {
                        path: "seller",
                        name: "onboarding.seller",
                        component: () =>
                            import(
                                /* webpackChunkName: "onboarding-seller" */ "@/views/onboarding/seller/Index.vue"
                            ),
                    },
                    /*{
                        path: "vendor",
                        name: "onboarding.vendor",
                        component: () =>
                            import(
                                /!* webpackChunkName: "onboarding-vendor" *!/ "@/views/onboarding/vendor/Index.vue"
                            ),
                    },*/
                    /*{
                        path: platformInfoMap[Platform.buyist].onboarding || "",
                        name: `onboarding.${
                            platformInfoMap[Platform.buyist].onboarding
                        }`,
                        component: () =>
                            import(
                                /!* webpackChunkName: "onboarding-buyist" *!/ "@/views/onboarding/buyist/Index.vue"
                            ),
                    },*/
                ],
            },
        ],
    },
];

const PublicRoutes: Array<RouteRecordRaw> = [
    {
        path: "/",
        component: () =>
            import(/* webpackChunkName: "auth" */ "@/views/auth/Index.vue"),
        children: [
            {
                path: "/login",
                name: "login",
                component: () =>
                    import(
                        /* webpackChunkName: "login" */ "@/views/auth/Login.vue"
                    ),
            },
            {
                path: "/forgot-password",
                name: "forgot-password",
                component: () =>
                    import(
                        /* webpackChunkName: "forgot-password" */ "@/views/auth/ForgotPassword.vue"
                    ),
            },
            {
                path: "/reset-password/:token",
                name: "reset-password",
                component: () =>
                    import(
                        /* webpackChunkName: "reset-password" */ "@/views/auth/ResetPassword.vue"
                    ),
            },
        ],
    },
    {
        path: "/reports/:company_id",
        name: "report.view",
        component: () =>
            import(
                /* webpackChunkName: "report-view" */ "@/views/clients/reports/public/View.vue"
            ),
    },
    {
        path: "/forms/:form_id/view",
        name: "form.view",
        component: () =>
            import(
                /* webpackChunkName: "form-view" */ "@/views/forms/View.vue"
            ),
    },
];

const FourOhFourRoutes: Array<RouteRecordRaw> = [
    /*{
        path: "/404",
        redirect: "/",
    },*/
    {
        // path: "/:pathMatch(.*)*",
        path: "/:catchAll(.*)",
        component: import(
            /* webpackChunkName: "404" */ "@/views/errors/404.vue"
        ),
    },
];

export const routes = AuthenticatedRoutes.concat(
    PublicRoutes,
    FourOhFourRoutes
);

const router = createRouter({
    history: createWebHistory(),
    routes,
    /*scrollBehavior(to, from ,savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }*/
});

router.beforeEach((to, from, next) => {
    // ✅ This will work because the router starts its navigation after
    // the router is installed and pinia will be installed too
    const authStore = useAuthStore();
    const { authUser, isUserLoggedIn } = storeToRefs(authStore);

    console.log(
        {
            from: from.name,
            to: to.name,
        },
        "router :: beforeEach"
    );

    /**
     * If a user is not logged and tried to access
     * - A protected Route
     * - A non existant Route
     * they will be redirected to /login with the
     * attempted path as a redirect
     */
    if (to.matched.some((m) => m.meta.requiresAuth) && !isUserLoggedIn.value) {
        return next({
            name: "login",
            query: {
                ...to.query,
                expired: "true",
                returnTo: encodeURIComponent(to.path),
            },
        });
    }

    // Go to client dashboard
    if (to.name === "dashboard" && authUser.value?.type === UserType.CONTACT) {
        return next(`/clients/${authUser.value.client?.id}`);
    }

    // Scroll page to top on every route change
    /*setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);*/

    return next();
});

export default router;
