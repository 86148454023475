import DOMEventHelper from "@/utils/DOMEventHelper";
import ElementStyleHelper from "@/utils/ElementStyleHelper";
import { getElementOffset } from "@/utils/dom";

export default class ElementAnimateHelper {
    public static animate(
        from: number,
        to: number,
        duration: number,
        // eslint-disable-next-line @typescript-eslint/ban-types
        update: Function,
        // eslint-disable-next-line @typescript-eslint/ban-types
        complete?: Function
    ) {
        /**
         * TinyAnimate.easings
         *  Adapted from jQuery Easing
         */
        const easings = {
            linear(t: number, b: number, c: number, d: number) {
                return (c * t) / d + b;
            },
        };

        // Create mock done() function if necessary
        if (!complete) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            complete = function () {};
        }

        // Animation loop
        // let canceled = false;
        const change = to - from;

        function loop(timestamp: number) {
            const time = (timestamp || +new Date()) - start;

            if (time >= 0) {
                update(easings.linear(time, from, change, duration));
            }
            if (time >= 0 && time >= duration) {
                update(to);
                if (complete) {
                    complete();
                }
            } else {
                window.requestAnimationFrame(loop);
            }
        }

        update(from);

        // Start animation loop
        const start =
            window.performance && window.performance.now
                ? window.performance.now()
                : +new Date();

        window.requestAnimationFrame(loop);
    }

    public static animateClass(
        element: HTMLElement,
        animationName: string,
        // eslint-disable-next-line @typescript-eslint/ban-types
        callBack?: Function
    ): void {
        const animateClasses = animationName.split(" ");
        animateClasses.forEach((cssClass) => element.classList.add(cssClass));
        DOMEventHelper.one(element, "animationend", () => {
            animateClasses.forEach((cssClass) =>
                element.classList.remove(cssClass)
            );
        });

        if (callBack) {
            DOMEventHelper.one(element, "animationend", callBack);
        }
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    public static transitionEnd(element: HTMLElement, callBack: Function) {
        DOMEventHelper.one(element, "transitionend", callBack);
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    public static animationEnd(element: HTMLElement, callBack: Function) {
        DOMEventHelper.one(element, "animationend", callBack);
    }

    public static animationDelay(element: HTMLElement, value: string) {
        ElementStyleHelper.set(element, "animation-delay", value);
    }

    public static animationDuration(element: HTMLElement, value: string) {
        ElementStyleHelper.set(element, "animation-duration", value);
    }

    public static scrollTo(
        element: HTMLElement | null,
        offset: number,
        duration = 500
    ) {
        let targetPos = element ? getElementOffset(element).top : 0;
        let scrollPos =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0;

        if (offset) {
            scrollPos += offset;
            targetPos = targetPos - offset;
        }

        const from = scrollPos;
        const to = targetPos;

        ElementAnimateHelper.animate(from, to, duration, (value: number) => {
            document.documentElement.scrollTop = value;
            // document.body.parentNode.scrollTop = value;
            document.body.scrollTop = value;
        }); //, easing, done
    }
    public static scrollTop(offset: number, duration: number) {
        ElementAnimateHelper.scrollTo(null, offset, duration);
    }
}
