import { LocalStorageService } from "@/services/LocalStorageService";

export const cacheKeys = {
    sidebarCollapsed: "sidebar_collapsed",

    vendorRegistrationKey: "vendor_registration_key",
    vendorRegistrationStep: "vendor_registration_step",

    sellerRegistrationStep: "seller_registration_step",
    sellerRegistrationClientId: "seller_registration_client_id",
    sellerRegistrationCompanyId: "seller_registration_company_id",
    sellerRegistrationCompanyName: "seller_registration_company_name",
    sellerRegistrationRegion: "seller_registration_region",

    buyistRegistrationClientId: "buyist_registration_client_id",
    buyistRegistrationCompanyId: "buyist_registration_company_id",
};

export function setItem(key: string, data: unknown) {
    LocalStorageService.set(key, data);
    window.dispatchEvent(new CustomEvent(key, { detail: data }));
}

export function getItem(key: string) {
    return LocalStorageService.get(key);
}

export function deleteItem(key: string) {
    LocalStorageService.remove(key);
}
