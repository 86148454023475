import DataHelper from "@/utils/DataHelper";
import { domId } from "@/utils/dom";

export interface EventMeta {
    name: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    callback: Function;
    one: boolean;
    fired: boolean;
}

export default class EventHandlerHelper {
    static store: Map<string, Map<string, EventMeta>> = new Map();

    private static setEventMetasByName(
        name: string,
        metas: Map<string, EventMeta>
    ): void {
        EventHandlerHelper.store.set(name, metas);
    }

    private static getEventMetasByName(
        name: string
    ): Map<string, EventMeta> | undefined {
        return EventHandlerHelper.store.get(name);
    }

    private static setEventMetaByNameAndHandlerId(
        name: string,
        handlerId: string,
        meta: EventMeta
    ): void {
        let metas = EventHandlerHelper.getEventMetasByName(name);
        if (!metas) {
            metas = new Map();
        }

        metas.set(handlerId, meta);
        EventHandlerHelper.setEventMetasByName(name, metas);
    }

    private static getEventsMetaByHandlerId(
        name: string,
        handlerId: string
    ): EventMeta | undefined {
        const metas = EventHandlerHelper.store.get(name);
        if (!metas) {
            return;
        }

        return metas.get(handlerId);
    }

    private static setFiredByNameAndHandlerId(
        name: string,
        handerId: string,
        fired: boolean
    ): void {
        const meta = EventHandlerHelper.getEventsMetaByHandlerId(
            name,
            handerId
        );
        if (!meta) {
            return;
        }

        meta.fired = fired;
        EventHandlerHelper.setEventMetaByNameAndHandlerId(name, handerId, meta);
    }

    private static addEvent(
        element: HTMLElement,
        name: string,
        // eslint-disable-next-line @typescript-eslint/ban-types
        callback: Function,
        one = false
    ) {
        const handlerId = domId("event");
        DataHelper.set(element, name, handlerId);
        const meta: EventMeta = {
            name,
            callback,
            one,
            fired: false,
        };

        EventHandlerHelper.setEventMetaByNameAndHandlerId(
            name,
            handlerId,
            meta
        );
    }

    private static removeEvent(element: HTMLElement, name: string) {
        const handlerId = DataHelper.get(element, name);
        if (!handlerId) {
            return;
        }

        const metas = EventHandlerHelper.getEventMetasByName(name);
        if (!metas) {
            return;
        }

        metas.delete(handlerId);
        EventHandlerHelper.setEventMetasByName(name, metas);
    }

    public static trigger(element: HTMLElement, name: string, e?: Event) {
        if (DataHelper.has(element, name)) {
            const handlerId = DataHelper.get(element, name);
            if (!handlerId) {
                return undefined;
            }

            const handler = EventHandlerHelper.getEventsMetaByHandlerId(
                name,
                handlerId
            );
            if (handler) {
                if (handler.name === name) {
                    if (handler.one === true) {
                        if (handler.fired === false) {
                            EventHandlerHelper.setFiredByNameAndHandlerId(
                                name,
                                handlerId,
                                true
                            );
                            return handler.callback.call(this, e);
                        }
                    } else {
                        return handler.callback.call(this, e);
                    }
                }
            }
        }

        return null;
    }

    public static on = function (
        element: HTMLElement,
        name: string,
        // eslint-disable-next-line @typescript-eslint/ban-types
        callBack: Function
    ): void {
        EventHandlerHelper.addEvent(element, name, callBack, false);
    };

    public static one(
        element: HTMLElement,
        name: string,
        // eslint-disable-next-line @typescript-eslint/ban-types
        callBack: Function
    ): void {
        EventHandlerHelper.addEvent(element, name, callBack, true);
    }

    public static off(element: HTMLElement, name: string): void {
        EventHandlerHelper.removeEvent(element, name);
    }
}
