export const HTTP_UNAUTHORIZED = 401;
export const HTTP_TOO_MANY_REQUESTS = 429;
export const MAX_TIMEOUT_IN_SECONDS = 300;
export const MAX_RECIPIENTS = 20;

// Constants for Page sizes in Subscription and Reports table
export const PAGE_SIZE_50 = 50;
export const PAGE_SIZE_100 = 100;
export const PAGE_SIZE_200 = 200;
export const PAGE_SIZE_300 = 300;
export const DEFAULT_FIRST_PAGE_OFFSET = 0;
export const DEFAULT_INITIAL_TOTAL_RESULTS = 0;

// Defining constant for Report Period
export const REPORT_PERIODS_CUSTOM = "CUSTOM";
export const REPORT_PERIODS_TODAY = "TODAY";
export const REPORT_PERIODS_YESTERDAY = "YESTERDAY";
export const REPORT_PERIODS_LAST_7_DAYS = "LAST_7_DAYS";
export const REPORT_PERIODS_LAST_30_DAYS = "LAST_30_DAYS";
export const REPORT_PERIODS_LAST_WEEK = "LAST_WEEK";
export const REPORT_PERIODS_LAST_MONTH = "LAST_MONTH";
export const REPORT_PERIODS_THIS_WEEK = "THIS_WEEK";
export const REPORT_PERIODS_THIS_MONTH = "THIS_MONTH";
export const REPORT_PERIODS_LIFETIME = "LIFETIME";
export const REPORT_PERIODS_WEEK_TO_DATE = "WEEK_TO_DATE";

// Defining constant for represeting day, weeks, months
export const ONE_DAY = 1;
export const SIXTH_DAYS = 6;
export const SEVEN_DAYS = 7;
export const THIRDTEEN_DAYS = 13;
export const FOURTEEN_DAYS = 14;
export const TWENTYNINE_DAYS = 29;
export const THIRTY_DAYS = 30;
export const ONE_WEEK = 1;
export const ONE_MONTH = 1;

// Constants for app actions
export const REPORT_CATEGORY_SP = "sp";
export const REPORT_CATEGORY_SB = "hsa";
export const REPORT_CATEGORY_SBV = "sbv";
export const REPORT_CATEGORY_SD = "sd";
export const REPORT_CATEGORY_PDA = "pda";
export const REPORT_CATEGORY_PDA_LEGACY = "pda_legacy";
export const REPORT_CATEGORY_AA = "aa";

// export const TIMEZONE = "America/Los_Angeles";
export const TIMEZONE = "America/Denver";

export const DEFAULT_LOCALE = "en_US";

export const DEFAULT_DATE_TIME_FORMAT = "MMM D, YYYY h:mm a z";

export const COUNTRY_MAP = {
    CA: "Canada",
    US: "United States",
    MX: "Mexico",
    ES: "Spain",
    UK: "United Kingdom",
    FR: "France",
    DE: "Germany",
    IT: "Italy",
    BR: "Brazil",
    IN: "India",
    CN: "China",
    JP: "Japan",
    AU: "Australia",
    GB: "Great Britain",
    AE: "UAE",
    NL: "Netherlands",
    SA: "Saudi Arabia",
    SG: "Singapore",
    SE: "Sweden",
    PL: "Poland",
};
