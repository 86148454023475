import { ConfigService } from "@/services/ConfigService";
import { BugTrackerService } from "@/services/BugTrackerService";

const bugtracker = new BugTrackerService({ limit: 10 });

const debug = (mod, ...args) => {
    if (ConfigService.env === "local") {
        console.log("[ES][" + mod + "]", ...args);
    }
};

const safe = (fn, ...args) => {
    try {
        fn(...args);
    } catch (e) {
        debug("Debug", "Error:", e);
        bugtracker.send(e);
        return false;
    }
    return true;
};

const enableLogSentry = () => {
    bugtracker.enabled = true;
};

const log = {
    warn: (...args) => {
        console.warn("[ecomSugar]", ...args);
    },
    info: (...args) => {
        console.log("[ecomSugar]", ...args);
    },
};

export { safe, log, debug, enableLogSentry };
