import { getUUID } from "./random";
import { useNoticeStore } from "@/stores/NoticeStore";

export const showNotice = (type: string, message: string) => {
    const noticeStore = useNoticeStore();
    const { addNotice } = noticeStore;
    // const id = (Math.random().toString(36) + Date.now().toString(36)).substr(2);

    addNotice({
        id: getUUID(),
        type,
        message,
    });
};

export const hideNotice = (id: string, type: string, message: string) => {
    const noticeStore = useNoticeStore();
    const { removeNotice } = noticeStore;

    removeNotice({
        id,
        type,
        message,
    });
};
