import { isString, isRegExp, isVueViewModel } from "@/types";

export function toObject(input: string): object {
    return input.split(/[\r\n]+/).reduce((e, t) => {
        const n = e;
        const r = t.split(": ");
        if (r.length > 1) {
            const o = r.shift() || "";
            const i = r.join(": ");
            e[o] ? (n[o] += ", " + i) : (n[o] = i);
        }
        return n;
    }, {});
}

export function toJSON(value: string | JSON): JSON | undefined {
    if (typeof value !== "string") {
        return value;
    }

    if (!value) {
        return undefined;
    }

    // ("'" => "\"");
    const result = value
        .toString()
        .split("")
        .map((el) => (el !== "'" ? el : '"'))
        .join("");
    const jsonStr = result.replace(/(\w+:)|(\w+ :)/g, (matched) => {
        return `"${matched.substring(0, matched.length - 1)}":`;
    });
    try {
        return JSON.parse(jsonStr);
    } catch {
        return undefined;
    }
}

export function bytesSize(str: string): number {
    // return encodeURI(str).split(/%..|./).length - 1;
    return new Blob([str]).size;
}

export function endsWith(str: string, suffix: string): boolean {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

export function camelToSnake(str) {
    if (str === null || str === undefined) {
        return "";
    }

    return str
        .split(/(?=[A-Z])/)
        .join("_")
        .toLowerCase();
}

export function snakeToCamel(text: string) {
    return text.replace(/([-_][a-z])/g, (group) =>
        group.toUpperCase().replace("_", "")
    );
}

export function trim(str: string): string {
    return str.replace(/^\s+|\s+$/g, "");
}

/**
 * Replaces slug special chars with a space
 */
export function explodeSlug(slug: string): string {
    return trim(slug.replace(/[-_]+/g, " "));
}

export function nl2br(str: string): string {
    return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
}

export function escape(str: string): string {
    return str
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
}

// NOTE: only escapes a " if it's not already escaped
export function escapeDoubleQuotes(str: string) {
    return str.replace(/\\([\s\S])|(")/g, "\\$1$2");
}

export function toTitleCase(str: string): string {
    return str.replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
    );
}

/**
 * Truncates given string to the maximum characters count
 *
 * @param str An object that contains serializable values
 * @param max Maximum number of characters in truncated string (0 = unlimited)
 * @returns string Encoded
 */
export function truncate(str, max = 0) {
    if (typeof str !== "string" || max === 0) {
        return str;
    }
    return str.length <= max ? str : `${str.slice(0, max)}...`;
}

/**
 * Trim strings with a preference for preserving whole words. Only cut up
 * whole words if the last remaining words are still too long.
 *
 * @param value The string to trim
 * @param maxLength The maximum length of the string
 * @param delimiter The delimiter to split the string by. If passing a regex be aware that the algorithm only supports single-character delimiters.
 *
 * **Examples:**
 *
 * - javascript project backend  -> javascript…backend
 * - my long sentry project name -> my long…project name
 * - javascriptproject backend   -> javascriptproj…ackend
 */
export function middleEllipsis(
    value: string,
    maxLength: number,
    delimiter: string | RegExp = " "
) {
    // Return the original slug if it's already shorter than maxLength
    if (value.length <= maxLength) {
        return value;
    }

    /**
     * Array of words inside the string.
     * E.g. "my project name" becomes ["my", "project", "name"]
     */
    const words: string[] = value.split(delimiter);
    const delimiters = Array.from(
        value.match(new RegExp(delimiter, "g")) || []
    );

    // If the string is too long but not hyphenated, return an end-trimmed
    // string. E.g. "javascriptfrontendproject" --> "javascriptfrontendp…"
    if (words.length === 1) {
        return `${value.slice(0, maxLength - 1)}\u2026`;
    }

    /**
     * Returns the length (total number of letters plus hyphens in between
     * words) of the current words array.
     */
    function getLength(arr: string[]): number {
        return arr.reduce((acc, cur) => acc + cur.length + 1, 0) - 1;
    }

    // Progressively remove words and delimiters in the middle until we're below maxLength,
    // or when only two words are left
    while (getLength(words) > maxLength && words.length > 2) {
        words.splice(Math.floor(words.length / 2 - 0.5), 1);
    }

    // If the remaining words array satisfies the maxLength requirement,
    // return the trimmed result.
    if (getLength(words) <= maxLength) {
        const divider = Math.floor(words.length / 2);
        const firstHalf = words.slice(0, divider);
        const firstHalfWithDelimiters = firstHalf
            .map((word, i) =>
                i === divider - 1 ? [word] : [word, delimiters[i]]
            )
            .flat();

        const secondHalf = words.slice(divider);
        const secondHalfWithDelimiters = secondHalf
            .map((word, i) =>
                i === 0
                    ? [word]
                    : [
                          delimiters[delimiters.length - secondHalf.length + i],
                          word,
                      ]
            )
            .flat();

        return `${firstHalfWithDelimiters.join(
            ""
        )}\u2026${secondHalfWithDelimiters.join("")}`;
    }

    // If the remaining 2 words are still too long, trim those words starting
    // from the middle.
    const debt = getLength(words) - maxLength;
    const toTrimFromLeftWord = Math.ceil(debt / 2);
    const leftWordLength = Math.max(words[0].length - toTrimFromLeftWord, 3);
    const leftWord = words[0].slice(0, leftWordLength);
    const rightWordLength = maxLength - leftWord.length;
    const rightWord = words[1].slice(-rightWordLength);

    return `${leftWord}\u2026${rightWord}`;
}

/**
 * This is basically just `trim_line` from
 * https://github.com/getsentry/sentry/blob/master/src/sentry/lang/javascript/processor.py#L67
 *
 * @param str An object that contains serializable values
 * @param max Maximum number of characters in truncated string
 * @returns string Encoded
 */
export function snipLine(line, colno) {
    let newLine = line;
    const lineLength = newLine.length;
    if (lineLength <= 150) {
        return newLine;
    }
    if (colno > lineLength) {
        // eslint-disable-next-line no-param-reassign
        colno = lineLength;
    }

    let start = Math.max(colno - 60, 0);
    if (start < 5) {
        start = 0;
    }

    let end = Math.min(start + 140, lineLength);
    if (end > lineLength - 5) {
        end = lineLength;
    }
    if (end === lineLength) {
        start = Math.max(end - 140, 0);
    }

    newLine = newLine.slice(start, end);
    if (start > 0) {
        newLine = `'{snip} ${newLine}`;
    }
    if (end < lineLength) {
        newLine += " {snip}";
    }

    return newLine;
}

/**
 * Join values in array
 * @param input array of values to be joined together
 * @param delimiter string to be placed in-between values
 * @returns Joined values
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function safeJoin(input, delimiter) {
    if (!Array.isArray(input)) {
        return "";
    }

    const output: any[] = [];

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < input.length; i++) {
        const value = input[i];
        try {
            // This is a hack to fix a Vue3-specific bug that causes an infinite loop of
            // console warnings. This happens when a Vue template is rendered with
            // an undeclared variable, which we try to stringify, ultimately causing
            // Vue to issue another warning which repeats indefinitely.
            // see: https://github.com/getsentry/sentry-javascript/pull/8981
            if (isVueViewModel(value)) {
                output.push("[VueViewModel]");
            } else {
                output.push(String(value));
            }
        } catch (e) {
            output.push("[value cannot be serialized]");
        }
    }

    return output.join(delimiter);
}

/**
 * Checks if the given value matches a regex or string
 *
 * @param value The string to test
 * @param pattern Either a regex or a string against which `value` will be matched
 * @param requireExactStringMatch If true, `value` must match `pattern` exactly. If false, `value` will match
 * `pattern` if it contains `pattern`. Only applies to string-type patterns.
 */
export function isMatchingPattern(
    value,
    pattern,
    requireExactStringMatch = false
) {
    if (!isString(value)) {
        return false;
    }

    if (isRegExp(pattern)) {
        return pattern.test(value);
    }

    if (isString(pattern)) {
        return requireExactStringMatch
            ? value === pattern
            : value.includes(pattern);
    }

    return false;
}

/**
 * Test the given string against an array of strings and regexes. By default, string matching is done on a
 * substring-inclusion basis rather than a strict equality basis
 *
 * @param testString The string to test
 * @param patterns The patterns against which to test the string
 * @param requireExactStringMatch If true, `testString` must match one of the given string patterns exactly in order to
 * count. If false, `testString` will match a string pattern if it contains that pattern.
 * @returns
 */
export function stringMatchesSomePattern(
    testString,
    patterns = [],
    requireExactStringMatch = false
) {
    return patterns.some((pattern) =>
        isMatchingPattern(testString, pattern, requireExactStringMatch)
    );
}

/**
 * Converts a multi-line textarea input value into an array,
 * eliminating empty lines
 */
export function extractMultilineFields(value: string): string[] {
    return value
        .split("\n")
        .map((f) => trim(f))
        .filter((f) => f !== "");
}

/**
 * If the value is of type Array, converts it to type string, keeping the line breaks, if there is any
 */
export function convertMultilineFieldValue<T extends string | string[]>(
    value: T
): string {
    if (Array.isArray(value)) {
        return value.join("\n");
    }

    if (typeof value === "string") {
        return value.split("\n").join("\n");
    }

    return "";
}

/**
 * Pluralizes a word based on the count. This is temporary as the pluralize package
 * doesn't have ts defintions.
 *
 * @param count
 * @param word
 * @returns string
 */
export function pluralize(count: number, word: string): string {
    if (count === 1) return word;

    return `${word}s`;
}
