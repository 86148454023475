import { createApp } from "vue";
import { createPinia } from "pinia";
import { enableLogSentry } from "@/utils/debugHelper";
import { setMomentLocale } from "@/utils/localeHelper";
import App from "@/App.vue";
import router from "@/router";
import ApiService from "@/services/ApiService";
// import { initApexCharts } from "@/plugins/apexcharts";
import { initInlineSvg } from "@/plugins/inline-svg";

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);

enableLogSentry();
setMomentLocale();

ApiService.init(app);
// initApexCharts(app);
initInlineSvg(app);

app.mount("#app");
