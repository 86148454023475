export function getUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

/*// sentry.io : guid.tsx
const s4 = (): string =>
    Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);

export function uniqueId(): string {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}*/

export const generateRandomPassword = (length = 8) =>
    (Math.random().toString(36) + Date.now().toString(36)).substr(2, length);
