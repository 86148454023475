import { IBreadcrumb } from "@/types";
import { ConfigService } from "@/services/ConfigService";
import { useUIStore } from "@/stores/UIStore";
import { Menu } from "@/components/Menu";
import { Scroll } from "@/components/Scroll";
import { Sticky } from "@/components/Sticky";
import { Toggle } from "@/components/Toggle";
import { Drawer } from "@/components/Drawer";
import { Swapper } from "@/components/Swapper";

export function configurePageHeader(
    title: string,
    description = "",
    breadcrumbs: IBreadcrumb[] = []
) {
    const store = useUIStore();
    const { setTitle } = store;

    setTitle(title, description, breadcrumbs);
}

export function setPageTitle(title: string, showAsterix = false) {
    if (title !== "") {
        window.document.title = `${showAsterix ? "* " : ""}${title} - ${
            ConfigService.app_name
        }`;
    } else {
        window.document.title =
            (showAsterix ? "* " : "") + ConfigService.app_name;
    }
}

export function initializeComponents() {
    setTimeout(() => {
        Toggle.bootstrap();
        Sticky.bootstrap();
        Menu.bootstrap();
        Scroll.bootstrap();
        Drawer.bootstrap();
        Swapper.bootstrap();
    }, 0);
}

export async function reinitializeScroll() {
    await Scroll.reinitialization();
}

export function reinitializeComponents() {
    setTimeout(() => {
        Toggle.reinitialization();
        Sticky.reInitialization();
        Menu.reinitialization();
        reinitializeScroll().then(() => {
            Scroll.updateAll();
        });
        Drawer.reinitialization();
        Swapper.reinitialization();
    }, 0);
}
