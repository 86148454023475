import * as ls from "local-storage";
import { IAuthUser } from "@/types";

// ls.backend(sessionStorage);

const storageKey = "api_user";

export const getAuthUser = () => {
    return ls.get<IAuthUser | null>(storageKey);
};

export const hasAuthUser = () => {
    return Boolean(getAuthUser());
};

export const saveAuthUser = (user: IAuthUser) => {
    ls.set(storageKey, user);
};

export const destroyAuthUser = () => {
    ls.remove(storageKey);
};

export default { getAuthUser, hasAuthUser, saveAuthUser, destroyAuthUser };
