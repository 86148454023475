import moment from "moment-timezone";
import { TIMEZONE } from "./constants";

export const getLocaleAndCurrency = (countryCode, userLanguage) => {
    let locale = "";
    let currency = "";

    switch (countryCode) {
        case "IN":
            locale = "gu-IN";
            currency = "INR";
            break;
        case "GB":
        case "UK":
            locale = "en-GB";
            currency = "GBP";
            break;
        case "JP":
            locale = "ja-JP";
            currency = "JPY";
            break;
        case "CN":
            locale = "zh";
            currency = "CNY";
            break;
        case "BR":
            locale = "pt-BR";
            currency = "BRL";
            break;
        case "FR":
            locale = "fr-FR";
            currency = "EUR";
            break;
        case "DE":
            locale = "de-DE";
            currency = "EUR";
            break;
        case "IT":
            locale = "it-IT";
            currency = "EUR";
            break;
        case "ES":
            locale = "es-ES";
            currency = "EUR";
            break;
        case "SE":
            locale = "se-SE";
            currency = "SEK";
            break;
        case "AU":
            locale = "en-AU";
            currency = "AUD";
            break;
        case "US":
            locale = "en-US";
            currency = "USD";
            break;
        case "CA":
            locale = "en-CA";
            currency = "CAD";
            break;
        case "MX":
            locale = "es-MX";
            currency = "MXN";
            break;
        case "AE":
            locale = "en-US";
            currency = "AED";
            break;
        case "NL":
            locale = "nl-NL";
            currency = "EUR";
            break;
        case "SA":
            locale = `${userLanguage}-SA`;
            currency = "SAR";
            break;
        case "SG":
            locale = "en-SG";
            currency = "SGD";
            break;
        case "PL":
            locale = "pl-PL";
            currency = "PLN";
            break;
        default:
            locale = "en-US";
            currency = "USD";
            break;
    }
    return {
        locale,
        currency,
    };
};

export function setMomentLocale(locale?: string) {
    moment.locale(locale);
    moment.tz.setDefault(TIMEZONE);
}
